


























































































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import OutstandingComponent from "./Outstanding.component";

@Component
export default class Outstanding extends Mixins(OutstandingComponent) {}
